<template>
    <div class="vx-row">

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Title Color"
                title-color="primary"
                subtitle="Brownie pastry chocolate pastry chocolate pudding."
                code-toggler>
                <p class="mb-3">You can use <code>title-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Title Color&quot;
    title-color=&quot;primary&quot;
    subtitle=&quot;Brownie pastry chocolate pastry chocolate pudding.&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;code&gt;title-color&lt;/code&gt; prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- TITLE COLOR -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Title Color"
                title-color="success"
                subtitle="Brownie pastry chocolate pastry chocolate pudding."
                code-toggler>
                <p class="mb-3">You can use <code>title-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Title Color&quot;
    title-color=&quot;success&quot;
    subtitle=&quot;Brownie pastry chocolate pastry chocolate pudding.&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;code&gt;title-color&lt;/code&gt; prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- SUBTITLE COLOR -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Subtitle Color"
                title-color="primary"
                subtitle-color="warning"
                subtitle="Brownie pastry chocolate pastry chocolate pudding."
                code-toggler>
                <p class="mb-3">You can use <code>subtitle-color</code> prop to change color of subtitle of card. This prop supports hex, rgba, rgb and theme colors.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Subtitle Color&quot;
    title-color=&quot;primary&quot;
    subtitle-color=&quot;warning&quot;
    subtitle=&quot;Brownie pastry chocolate pastry chocolate pudding.&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;code&gt;subtitle-color&lt;/code&gt; prop to change color of subtitle of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- CONTENT COLOR -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Content Color"
                title-color="primary"
                content-color="warning"
                subtitle="Brownie pastry chocolate pastry chocolate pudding."
                code-toggler>
                <p class="mb-3">You can use <code>content-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Content Color&quot;
    title-color=&quot;primary&quot;
    content-color=&quot;warning&quot;
    subtitle=&quot;Brownie pastry chocolate pastry chocolate pudding.&quot;
    code-toggler&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;code&gt;content-color&lt;/code&gt; prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- Background Color -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Background Color"
                title-color="#fff"
                card-background="primary"
                content-color="#fff"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Background Color&quot;
    title-color=&quot;#fff&quot;
    card-background=&quot;primary&quot;
    content-color=&quot;#fff&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- Background Color -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Background Color"
                title-color="#fff"
                card-background="success"
                content-color="#fff"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Background Color&quot;
    title-color=&quot;#fff&quot;
    card-background=&quot;success&quot;
    content-color=&quot;#fff&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>

        <!-- Gradient Background Color -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Gradient Background Color"
                title-color="#fff"
                content-color="#fff"
                card-background="linear-gradient(120deg, #7f7fd5, #86a8e7, #91eae4)"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Gradient Background Color&quot;
    title-color=&quot;#fff&quot;
    content-color=&quot;#fff&quot;
    card-background=&quot;linear-gradient(120deg, #7f7fd5, #86a8e7, #91eae4)&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>


        <!-- Gradient Background Color -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Gradient Background Color"
                title-color="#fff"
                content-color="#fff"
                card-background="linear-gradient(to right, #56ab2f, #a8e063)"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;vx-card
    title=&quot;Gradient Background Color&quot;
    title-color=&quot;#fff&quot;
    content-color=&quot;#fff&quot;
    card-background=&quot;linear-gradient(to right, #56ab2f, #a8e063)&quot;&gt;
    &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
    &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
&lt;/vx-card&gt;
                </template>
            </vx-card>
        </div>


        <!-- Gradient Background Image -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Gradient Background Image"
                title-color="#fff"
                content-color="#fff"
                :card-background="'linear-gradient(120deg ,rgba(109,213,237,.8), rgba(33,147,176,0.5)), url(' + card_bg_img_1 + ')'"
                class="card-overlay bg-cover"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;template&gt;
  &lt;vx-card
      title=&quot;Gradient Background Image&quot;
      title-color=&quot;#fff&quot;
      content-color=&quot;#fff&quot;
      :card-background=&quot;'linear-gradient(120deg ,rgba(109,213,237,.8), rgba(33,147,176,0.5)), url(' + card_bg_img_1 + ')'&quot;
      code-toggler&gt;
      &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
      &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
  &lt;/vx-card&gt;
&lt;/template&gt;

&lt;script&gt;
export default{
  data() {
    return {
      card_bg_img_1: require('@/assets/images/pages/card-bg-image-demo-1.jpg')
    }
  }
}
&lt;/script&gt;
                </template>
            </vx-card>
        </div>


        <!-- Gradient Background Image -->
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card
                title="Gradient Background Image"
                title-color="#fff"
                content-color="#fff"
                :card-background="'linear-gradient(120deg ,rgba(247,97,161,0.5), rgba(140,27,171,.8)), url(' + card_bg_img_2 + ')'"
                class="card-overlay bg-cover"
                code-toggler>
                <p class="mb-3">You can use <strong>card-background</strong> prop to change background of card. This prop supports hex, rgba, rgb and theme colors.</p>
                <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>

                <template slot="codeContainer">
&lt;template&gt;
  &lt;vx-card
      title=&quot;Gradient Background Image&quot;
      title-color=&quot;#fff&quot;
      content-color=&quot;#fff&quot;
      :card-background=&quot;'linear-gradient(120deg ,rgba(247,97,161,0.5), rgba(140,27,171,.8)), url(' + card_bg_img_2 + ')'&quot;
      code-toggler&gt;
      &lt;p class=&quot;mb-3&quot;&gt;You can use &lt;strong&gt;card-background&lt;/strong&gt; prop to change background of card. This prop supports hex, rgba, rgb and theme colors.&lt;/p&gt;
      &lt;p class=&quot;mb-3&quot;&gt;Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.&lt;/p&gt;
  &lt;/vx-card&gt;
&lt;/template&gt;

&lt;script&gt;
export default{
  data() {
    return {
      card_bg_img_2: require('@/assets/images/pages/card-bg-image-demo-2.jpg')
    }
  }
}
&lt;/script&gt;
                </template>
            </vx-card>
        </div>


    </div>
</template>

<script>
export default{
  data() {
    return {
      card_bg_img_1: require('@/assets/images/pages/card-bg-image-demo-1.jpg'),
      card_bg_img_2: require('@/assets/images/pages/card-bg-image-demo-2.jpg')
    }
  }
}
</script>
